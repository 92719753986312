import React from "react"

const Page = () => (
  <div>
    <h1>404</h1>
    <p>Page Not Found</p>
  </div>
)

export default Page
